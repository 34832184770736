import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2';
import { ageCalculate, sanitizeNumber } from '../global';
import formatAmount from 'indian-currency-formatter';
import SideImg from '../../img/Protection.png';
import axioInstance from '../axiosInstance';
import { Encryption } from '../global';

export default function CashflowScreen1({ setSubstep, substep, prevsubStep, nextsubStep, clientName, incomeSelfParam, incomeSpouseParam }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const onSubmit = data => {

    setButtonSpinner(true);

    const Postdata = {
      profile_id: sessionStorage.getItem('profile_id'),
      admin_id:sessionStorage.getItem('admin_id'),
      type: 2,
      consider: 1,
      step: "2",
      income: {
        ChildrenEducation: {
          "sub_type": "ChildrenEducation",
          "amount": sanitizeNumber(childrenEducationAmount),
          "frequency": data.ChildrenEducationFreq
        },
        Household: {
          "sub_type": "Household",
          "amount": sanitizeNumber(householdAmount),
          "frequency": data.HouseholdFreq
        },
        RentalExpense: {
          "sub_type": "rentalExpense",
          "amount": sanitizeNumber(rentalExpenseAmount),
          "frequency": data.rentalExpenseFreq
        },
        UtilityBills: {
          "sub_type": "UtilityBills",
          "amount": sanitizeNumber(utilityBillsAmount),
          "frequency": data.UtilityBillsFreq
        },
        Lifestyle: {
          "sub_type": "Lifestyle",
          "amount": sanitizeNumber(lifestyleAmount),
          "frequency": data.LifestyleFreq
        },
        Vacation: {
          "sub_type": "Vacation",
          "amount": sanitizeNumber(VacationAmount),
          "frequency": data.VacationFreq
        }
      }
    };

    axioInstance.post(`profile-income`, Postdata)
      .then(function (response) {


        const selfLiParams = {
          "profile_id": sessionStorage.getItem('profile_id'),
          "profile_details_id": sessionStorage.getItem('profile_details_id'),
          "dob": ageCalculate(selfDetails.dob),
          "inflation": assumptions.inflation,
          "income_grow": assumptions.income_grow,
          "api_type": "insert",
          "goal_group": 2,
          "post_retire_inflation": assumptions.post_retire_inflation,
          "life_expentancy": assumptions.life_expentancy,
          "gi_dependents": 0,
          "income": 0,
          "min": 100000,
          "max": 1000000,
          "retire_age": assumptions.retire_age,
          "li_goal_id": selfLi ? selfLi.goal_id : '',
          "gi_goal_id": selfGi ? selfGi.goal_id : '',
          "auto_reset": "1",
          "select_opt": "1"
        }
        axioInstance.post(`goals/default`, selfLiParams).then(function (response) {

        })


        if (incomeSpouseParam > 0 && spouseDetails) {
          const goalParams = {
            "profile_id": sessionStorage.getItem('profile_id'),
            "spouse_prof_details_id": sessionStorage.getItem('spouse_prof_det_id'),
            "dob": ageCalculate(spouseDetails.dob),
            "inflation": assumptions.inflation,
            "income_grow": assumptions.income_grow,
            "api_type": "insert",
            "post_retire_inflation": assumptions.post_retire_inflation,
            "life_expentancy": assumptions.life_expentancy,
            "gi_dependents": 0,
            "income": 0,
            "min": 100000,
            "max": 1000000,
            "retire_age": assumptions.retire_age,
            "auto_reset": "1",
            "select_opt": "1",
            "goal_id": spouseLi ? spouseLi.goal_id : ''
          }
          axioInstance.post(`goals/add-spouse-life`, goalParams).then(function (response) {
            setButtonSpinner(false);
            setSubstep(nextsubStep(substep, 4))
          })
        } else {
          setButtonSpinner(false);
          setSubstep(nextsubStep(substep, 4))
        }

      })
      .catch(function (error) {
        console.log(error);
        SweetAlert.fire(
          'Expense',
          'Failed. Something went wrong. Retry again',
          'error'
        )
      });

  }


  const [expense, setExpense] = useState(null);

  const [Household, setHousehold] = useState(null);
  const [HouseholdFreq, setHouseholdFreq] = useState(null);
  const [HouseholdId, setHouseholdId] = useState(null);

  const [rentalExpense, setRentalExpense] = useState(null);
  const [rentalExpenseFreq, setRentalExpenseFreq] = useState(null);
  const [rentalExpenseId, setRentalExpenseId] = useState(null);

  const [UtilityBills, setUtilityBills] = useState(null);
  const [UtilityBillsFreq, setUtilityBillsFreq] = useState(null);
  const [UtilityBillsId, setUtilityBillsId] = useState(null);

  const [Lifestyle, setLifestyle] = useState(null);
  const [LifestyleFreq, setLifestyleFreq] = useState(null);
  const [LifestyleId, setLifestyleId] = useState(null);

  const [ChildrenEducation, setChildrenEducation] = useState(null);
  const [ChildrenEducationFreq, setChildrenEducationFreq] = useState(null);
  const [ChildrenEducationId, setChildrenEducationId] = useState(null);


  const [Vacation, setVacation] = useState(null);
  const [VacationFreq, setVacationFreq] = useState(null);
  const [VacationId, setVacationId] = useState(null);



  const [householdAmount, setHouseholdAmount] = useState();
  const [rentalExpenseAmount, setRentalExpenseAmount] = useState();
  const [utilityBillsAmount, setUtilityBillsAmount] = useState();
  const [lifestyleAmount, setLifestyleAmount] = useState();
  const [childrenEducationAmount, setChildrenEducationAmount] = useState();
  const [VacationAmount, setVacationAmount] = useState();

  const handleChange = (e) => {

    const { value } = e.target;

    if (e.target.name === 'Household') {
      setHouseholdAmount(formatAmount(sanitizeNumber(value)));
    } else if (e.target.name === 'rentalExpense') {
      setRentalExpenseAmount(formatAmount(sanitizeNumber(value)));
    } else if (e.target.name === 'UtilityBills') {
      setUtilityBillsAmount(formatAmount(sanitizeNumber(value)));
    } else if (e.target.name === 'Lifestyle') {
      setLifestyleAmount(formatAmount(sanitizeNumber(value)));
    } else if (e.target.name === 'ChildrenEducation') {
      setChildrenEducationAmount(formatAmount(sanitizeNumber(value)));
    } else if (e.target.name === 'Vacation') {
      setVacationAmount(formatAmount(sanitizeNumber(value)));
    }

  };


  const InputVal = (e, type) => {

    const inputExpense = sanitizeNumber(e.target.value);

    if (type === 1) {

      if (e.target.name === 'Household') {
        setHouseholdAmount(inputExpense === "0" ? '' : formatAmount(inputExpense));
      } else if (e.target.name === 'rentalExpense') {
        setRentalExpenseAmount(inputExpense === "0" ? '' : formatAmount(inputExpense));
      } else if (e.target.name === 'UtilityBills') {
        setUtilityBillsAmount(inputExpense === "0" ? '' : formatAmount(inputExpense));
      } else if (e.target.name === 'Lifestyle') {
        setLifestyleAmount(inputExpense === "0" ? '' : formatAmount(inputExpense));
      } else if (e.target.name === 'ChildrenEducation') {
        setChildrenEducationAmount(inputExpense === "0" ? '' : formatAmount(inputExpense));
      } else if (e.target.name === 'Vacation') {
        setVacationAmount(inputExpense === "0" ? '' : formatAmount(inputExpense));
      }

    } else if (type === 2) {

      if (e.target.name === 'Household') {
        setHouseholdAmount(inputExpense > "0" ? formatAmount(inputExpense) : 0);
      } else if (e.target.name === 'rentalExpense') {
        setRentalExpenseAmount(inputExpense > "0" ? formatAmount(inputExpense) : 0);
      } else if (e.target.name === 'UtilityBills') {
        setUtilityBillsAmount(inputExpense > "0" ? formatAmount(inputExpense) : 0);
      } else if (e.target.name === 'Lifestyle') {
        setLifestyleAmount(inputExpense > "0" ? formatAmount(inputExpense) : 0);
      } else if (e.target.name === 'ChildrenEducation') {
        setChildrenEducationAmount(inputExpense > "0" ? formatAmount(inputExpense) : 0);
      } else if (e.target.name === 'Vacation') {
        setVacationAmount(inputExpense > "0" ? formatAmount(inputExpense) : 0);
      }

    }

  }


  // eslint-disable-next-line
  const [spouseLi, setSpouseLi] = useState([]);

  // eslint-disable-next-line
  const [selfLi, setSelfLi] = useState([]);

  // eslint-disable-next-line
  const [selfGi, setSelfGi] = useState([]);

  // eslint-disable-next-line
  const [spouseDetails, setSpouseDetails] = useState([]);

  // eslint-disable-next-line
  const [selfDetails, setSelfDetails] = useState([]);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);



  useEffect(() => {
    const profile_id = sessionStorage.getItem('profile_id');


    if (profile_id !== null) {
      axioInstance.post(`get-income-expense`, { "profile_id": profile_id, "type": 2 }).then(
        (response, data) => {
          if (response.data.length > 0) {

            response.data.forEach(item => {

              switch (item.type_name_sf) {

                case 'Household':
                  setHouseholdId(item.income_id);
                  setHouseholdFreq(item.frequency);
                  setHousehold(item.amount);
                  break;

                case 'UtilityBills':
                  setUtilityBillsId(item.income_id);
                  setUtilityBillsFreq(item.frequency);
                  setUtilityBills(item.amount);
                  break;

                case 'ChildrenEducation':
                  setChildrenEducationId(item.income_id);
                  setChildrenEducationFreq(item.frequency);
                  setChildrenEducation(item.amount);
                  break;

                case 'Lifestyle':
                  setLifestyleId(item.income_id);
                  setLifestyleFreq(item.frequency);
                  setLifestyle(item.amount);
                  break;

                case 'Vacation':
                  setVacationId(item.income_id);
                  setVacationFreq(item.frequency);
                  setVacation(item.amount);
                  break;

                case 'rentalExpense':
                  setRentalExpenseId(item.income_id);
                  setRentalExpenseFreq(item.frequency);
                  setRentalExpense(item.amount);
                  break;

                default:
                  break;

              }

            });

            setExpense({
              Household: HouseholdId !== null ? formatAmount(Household, 0) : '',
              HouseholdFreq: HouseholdId !== null ? HouseholdFreq : 12,
              HouseholdId: HouseholdId,
              rentalExpense: rentalExpenseId !== null ? formatAmount(rentalExpense, 0) : '0',
              rentalExpenseFreq: rentalExpenseId !== null ? rentalExpenseFreq : 12,
              rentalExpenseId: rentalExpenseId,
              UtilityBills: UtilityBillsId !== null ? formatAmount(UtilityBills, 0) : '0',
              UtilityBillsFreq: UtilityBillsId !== null ? UtilityBillsFreq : 12,
              UtilityBillsId: UtilityBillsId,
              Lifestyle: LifestyleId !== null ? formatAmount(Lifestyle, 0) : '0',
              LifestyleFreq: LifestyleId !== null ? LifestyleFreq : 12,
              LifestyleId: LifestyleId,
              ChildrenEducation: ChildrenEducationId !== null ? formatAmount(ChildrenEducation) : '0',
              ChildrenEducationFreq: ChildrenEducationId !== null ? ChildrenEducationFreq : 12,
              ChildrenEducationId: ChildrenEducationId,
              Vacation: VacationId !== null ? formatAmount(Vacation) : '0',
              VacationFreq: VacationId !== null ? VacationFreq : 12,
              VacationId: VacationId
            });

            
            setHouseholdAmount(HouseholdId !== null ? formatAmount(Household, 0) : '');
            setRentalExpenseAmount(rentalExpenseId !== null ? formatAmount(rentalExpense, 0) : '0');
            setUtilityBillsAmount(UtilityBillsId !== null ? formatAmount(UtilityBills, 0) : '0');
            setLifestyleAmount(LifestyleId !== null ? formatAmount(Lifestyle, 0) : '0');
            setChildrenEducationAmount(ChildrenEducationId !== null ? formatAmount(ChildrenEducation) : '0');
            setVacationAmount(VacationId !== null ? formatAmount(Vacation) : '0');


          }
        });


        axioInstance.post(`profile/summary`, {profile_id:profile_id}).then(
        (response, data) => {
          if (response.data.status === 100) {



            let spouse_li_goal = response.data.risk_goals.find(product => product.plan_for === parseInt(sessionStorage.getItem('spouse_prof_det_id')));
            setSpouseLi(spouse_li_goal ? spouse_li_goal : '');

            let spouse_details = response.data.prof_det.find(product => product.relation === "spouse");
            setSpouseDetails(spouse_details ? spouse_details : false);

            let self_details = response.data.prof_det.find(product => product.relation === "self");
            setSelfDetails(self_details);

            let self_li_goal = response.data.risk_goals.find(product => (product.plan_for === parseInt(sessionStorage.getItem('profile_details_id')) && product.goal_type === "Life Insurance"));
            setSelfLi(self_li_goal ? self_li_goal : '');

            let self_gi_goal = response.data.risk_goals.find(product => (product.plan_for === parseInt(sessionStorage.getItem('profile_details_id')) && product.goal_type === "Health Insurance"));
            setSelfGi(self_gi_goal ? self_gi_goal : '');


            setAssumptions(response.data.assumption);


          }
        }, []);


        axioInstance.post(`profile-details/getfamily`, {profile_id:profile_id}).then(
          (response, data) => {
             
              let spouse_prof_det_id = response.data.find(product => product.relation === "spouse");

              sessionStorage.setItem('spouse_prof_det_id', spouse_prof_det_id ? Encryption(spouse_prof_det_id.profile_details_id) : null);

           
          });

    }
  }, [Household, HouseholdFreq, HouseholdId, rentalExpense, rentalExpenseFreq, rentalExpenseId, UtilityBills, UtilityBillsFreq, UtilityBillsId, Lifestyle, LifestyleFreq, LifestyleId, ChildrenEducation, ChildrenEducationFreq, ChildrenEducationId, Vacation, VacationFreq, VacationId]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(expense);
    // eslint-disable-next-line
  }, [expense]);

  return (
    <Fragment>

      <div className="mainwrapper">

        <div className='form_title'>
          <div className="mt30 font20 fw600">{clientName} Cashflow</div>
          <div className="font15 color495">we need to map a plan to match your goal. so we need your income infos.</div>
        </div>

        <div className="row formWpr mt25">
          <div className="col-lg-8 formouter">
            <ul className="form_swipe_tab font12 fw600">
              <li onClick={() => setSubstep(prevsubStep(substep))}>1</li>
              <li className="act">2</li>
              <li>3</li>
              <li>4</li>
            </ul>


            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="formtitle flex_center justify_center">
                <div>
                  <div className="font20 fw600">Expenses</div>
                  <div className="font15 color495 ">Also a bit approx but give us an idea of all the expenses you have.</div>
                </div>
                <div>

                  <button className="bluebtn font13 fw600 d-none d-md-block" disabled={!isDirty && !isValid}>{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"}
                    <span className="whitearrow"><svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 9C2.25217 7.6 3.26957 6.54783 4.87826 5.29565C5.06957 5.14783 5.06957 4.85217 4.87826 4.70435C3.26957 3.45217 2.25217 2.4 1 1" stroke="white" strokeWidth="1.30435" strokeMiterlimit="10" />
                    </svg></span></button>
                </div>
              </div>

              {/* Form */}


              <div className="p35">

                <div className="addincome_wpr">
                  <div className="font16 fw600 color212">{clientName} family’s expenses</div>
                </div>
    

                <div className="form-row mt20">
                  <div className="col-md-6 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Household expenses<span className="colorfa8">*</span></label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Household" {...register("Household", { required: 'Household Expense is required' })} value={householdAmount} onChange={handleChange} />
                      <select className="custom-select" id="inputGroupSelect01" name="HouseholdFreq" {...register("HouseholdFreq")} >
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.Household && errors.Household.message}</span>
                  </div>
                  <div className="col-md-6 pl20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Rent</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="rentalExpense" {...register("rentalExpense", { required: 'Rental Expense is required' })} value={rentalExpenseAmount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="rentalExpenseFreq" {...register("rentalExpenseFreq")} >
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.rentalExpense && errors.rentalExpense.message}</span>
                  </div>
                </div>

                <div className="form-row mt20">
                  <div className="col-md-6 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Utility Bills</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="UtilityBills" {...register("UtilityBills", { required: 'Utility Bills Expense is required' })} value={utilityBillsAmount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="UtilityBillsFreq" {...register("UtilityBillsFreq")} >
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.UtilityBills && errors.UtilityBills.message}</span>
                  </div>
                  <div className="col-md-6 pl20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Lifestyle expenses</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Lifestyle" {...register("Lifestyle", { required: 'Lifestyle Expense is required' })} value={lifestyleAmount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="LifestyleFreq" {...register("LifestyleFreq")} >
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.Lifestyle && errors.Lifestyle.message}</span>
                  </div>
                </div>

                <div className="formdevider mt35 mb35" />


                <div className="form-row mt25">
                  <div className="col-md-6 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Kids Education expenses</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="ChildrenEducation" {...register("ChildrenEducation", { required: 'Children Education Expense is required' })} value={childrenEducationAmount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="ChildrenEducationFreq" {...register("ChildrenEducationFreq")} >
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.ChildrenEducation && errors.ChildrenEducation.message}</span>
                  </div>
                  <div className="col-md-6 pl20">
                    <label htmlFor="inputEmail4" className="font14 fw600">Vacation expenses</label>
                    <div className="input-group selectarrow">
                      <input type="text" className="form-control color212 font14 fw500" aria-label="Text input with dropdown button" name="Vacation" {...register("Vacation", { required: 'Vacation Expense is required' })} value={VacationAmount} onChange={handleChange} onFocus={(e) => InputVal(e, 1)} onBlur={(e) => InputVal(e, 2)} />
                      <select className="custom-select" id="inputGroupSelect01" name="VacationFreq" {...register("VacationFreq")} >
                        <option value="12" selected>Monthly</option>
                        <option value="1">Yearly</option>
                      </select>
                      <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                    </div>
                    <span className="text-danger"> {errors.Vacation && errors.Vacation.message}</span>

                  </div>
                </div>
              </div>

              <div className="saveBtn backsaveBtn d-block d-sm-none">
                <button type="button" className="btn btn-primary" onClick={() => setSubstep(prevsubStep(substep))}> Back </button>
                <button type='submit' disabled={!isDirty && !isValid} className="btn btn-primary"> {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Save"} </button>
              </div>

            </form>

            {/* Form End */}

          </div>

          <div className="col-lg-4 qouteouter d-none d-md-block">
            <div className="qoutetext font18 fw500 color212 pl45 pr45">
              <div className="qoutesign">
                &#8220;
              </div>
              If you have other expenses, please click add expenses button and add them.
            </div>


            <div className="qouteimg" ><img src={SideImg} alt='Protection' /></div>
          </div>
        </div>

      </div>
    </Fragment>
  )
}
