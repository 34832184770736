import React, { Fragment, useState } from 'react'
import axioInstance from '../axiosInstance';
import { useForm } from 'react-hook-form'
import SweetAlert from 'sweetalert2'
import { Encryption } from '../global';

export default function LoginWithPassword({ setLoginStep, setLoginType, loginPara, setPopupContent, setShow, setPopupIcon, setPopupTitle }) {

  const { register, handleSubmit, formState: { errors } } = useForm();

  const [buttonSpinner, setButtonSpinner] = useState(false);

  const backbtn = () => {
    setLoginStep(1);
  }


  const LoginViaOtp = () => {

    setButtonSpinner(true);
    const Otpdata = {
      "full_name": loginPara.first_name,
      "mobile": loginPara.phone,
      "email": loginPara.email,
      "otp_send_on": 'mobile',
      "otp_for": "login"
    };

    axioInstance.post(`send-otp`, Otpdata).then(
      function (response, data) {
        setButtonSpinner(false);
        setLoginType('otp');
        setLoginStep(2);
      }
    )


  }

  const onSubmit = data => {

    setButtonSpinner(true);

    const Postdata = {
      "email": loginPara.email,
      "password": data.password
    };

    axioInstance.post(`login`, Postdata)
      .then(function (response) {


        SweetAlert.fire({
          toast: true,
          icon: 'success',
          title: 'Signed in Successfully',
          animation: false,
          position: 'top-right',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', SweetAlert.stopTimer)
            toast.addEventListener('mouseleave', SweetAlert.resumeTimer)
          }
        }).then(function () {

          console.log(response.data.data.token);

          sessionStorage.setItem('token', response.data.data.token);
          const pdata = { "email": loginPara.email };
          
          axioInstance.post(`profile/view`, pdata).then(function (response) {
            sessionStorage.setItem('profile_id', Encryption(response.data.profile_id.trim()));
            sessionStorage.setItem('full_name', response.data.first_name.trim() + '\'s');
            sessionStorage.setItem('profile_details_id', Encryption(response.data.profile_details_id.trim()));
            

            axioInstance.post(`login-logout-logs`, { user_type: 'client', action: 'login', profile_id: response.data.profile_id, login_via:'Password' }).then(function (response, data) {

              setButtonSpinner(false);
              if (response.data.step > 3) {
                window.location.href = "/dashboard";
              } else {
                window.location.href = "/basic-profile";
              }

            });


          });

          //

        });
      })
      .catch(e => {
        setPopupIcon('errorIcon')
        setPopupTitle('Login With Password');
        setPopupContent(e.response.data.message);
        setShow(true);
      });

  }

  const [password, setpassword] = useState();

  const handleChange = (e) => {
    const { value } = e.target;
    if (e.target.name === 'passwordFirst') {
      setpassword(value);
    }
  }

  const [togglePassword, setTogglePassword] = useState(false)

  const HideShowPassword = (tPassword) => {
    setTogglePassword(!tPassword)
  }


  return (
    <Fragment>
      <div className="logincard">
        <div className="logincard_title pt30 pb30 pl35 pr35">
          <div className="font18 color212 fw600">Verify Your Password</div>
          <div className="font13 fw500 color626">for account {loginPara.email}</div>
          <button type="button" className="btn btn-outline-primary btn-sm" onClick={backbtn}><span className="blueleftarrow mr5" /> Back</button>
        </div>
        <div className="p35">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="font14 fw600 color212">Enter Password<span className="colorfa8 ml5">*</span></div>
            <div className="mt12 mobilefield">
              <input type={togglePassword ? "text" : "password"} placeholder="Enter Password." name="password" autoComplete='off' {...register("password", { required: 'Password is required' })} onChange={handleChange} value={password} />
              <span className="eyeicon mr10" onClick={() => HideShowPassword(togglePassword)} />
            </div>
            <div className="error mt5 font13 coloreb4 dn">{errors.password && errors.password.message}</div>

            <button type="submit" className="btn btn-primary btn-lg btn-block font13 fw500 mt20 continuebtn">{buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Continue"}</button>
          </form>
          <div className="forgotpass_card mt10">
            <div className="rememberme">
              <div className="form-group">
                <div className="form-check">
                  <input className="form-check-input" type="checkbox" id="gridCheck" />
                  <label className="form-check-label font14 color626" for="gridCheck">
                    Remember Me
                  </label>
                </div>
              </div>
            </div>
            <div><span className="font14 fw600 color0d6 pointer" onClick={() => setLoginType('forgotpassword')}>Forgot password?</span></div>
          </div>

          <div className="orline mt40 mb40">
            <span className="font13 color7b8 fw600">OR</span>
          </div>

          <div className="googlebtn font15 fw600 color222 pointer" onClick={LoginViaOtp}>
            {buttonSpinner ? <><i className="fa fa-spinner fa-spin" aria-hidden="true"></i> loading</> : "Login via OTP"}
          </div>

          <div className="logintnctext font12 fw500 color485 mt35">
            By continuing, you agree with <a href='https://www.finnovate.in/terms-conditions' target='_blank' rel="noreferrer" className="color06d">Terms of Use</a> of Finnovate Financial Services Pvt Ltd. Our <a href='https://www.finnovate.in/privacy-policy' target='_blank' rel="noreferrer" className="color06d">Privacy Policy</a> describes how data is handled at Finnovate.
          </div>

        </div>

      </div>
    </Fragment>
  )
}
