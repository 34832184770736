import { useEffect, useState } from 'react';

const useCheckVersion = () => {
    const [isNewVersion, setIsNewVersion] = useState(false);

    useEffect(() => {
        const checkVersion = async () => {
            try {
                const response = await fetch('/version.json', {
                    cache: 'no-cache'
                });
                const { version: latestVersion } = await response.json();

                const storedVersion = localStorage.getItem('appVersion');

                console.log('storedVersion', storedVersion);
                console.log('latestVersion', latestVersion);

                if (parseInt(storedVersion) !== parseInt(latestVersion)) {
                    setIsNewVersion(true);
                } else {
                    //localStorage.setItem('appVersion', latestVersion);
                }


            } catch (error) {
                console.error('Error fetching version:', error);
            }
        };

        checkVersion();
    }, []);

    return isNewVersion;
};

export default useCheckVersion;
