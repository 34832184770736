import React from 'react';
//import Alert from 'react-bootstrap/Alert';
import Swal from "sweetalert2";

const RefreshSidebar = ({ isNewVersion, onRefresh }) => {

    if (isNewVersion) {

        Swal.fire({
            title: "Something has changed. Please refresh",
            icon: "info",
            html: `
             <p>
                        . <button id="refresh-button" class="btn btn-primary">
                            Refresh
                        </button>
                    </p>
            `,
            showCloseButton: true,
            showCancelButton: false,
            showConfirmButton: false,
            focusConfirm: false,
            customClass: {
                title: 'custom-swal-title' // Apply the custom CSS class to the title
            }
        });

        document.getElementById('refresh-button').addEventListener('click', onRefresh);
    }




    return (<>
        {/*isNewVersion && (
            <div style={{ position: 'fixed', bottom: 20, right: 20, zIndex: 9999, }}>
                <Alert show={true} variant="info">
                    <p>
                        New App Update. <button onClick={onRefresh} variant="btn btn-xs outline-primary">
                            Refresh
                        </button>
                    </p>
                </Alert>
            </div>
        )*/}

    </>

    );
};

export default RefreshSidebar;
