import React, { Fragment, useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
import {  ageCalculate } from '../../global';
//import formatAmount from 'indian-currency-formatter';
import RangeSlider from 'react-bootstrap-range-slider';
import { Encryption } from '../../global';

export default function AddWealth({ setAddGoal, aspire }) {

  const { register, handleSubmit, formState: { errors, isDirty, isValid } } = useForm();

  const Back = () => {
    setAddGoal('');
  }
  // eslint-disable-next-line
  const [max, setMax] = useState();

  const onSubmit = data => {

    const PostData = {
      "goal_type": "Wealth",
      "goal_name": aspire === "Dream Car" ? "Car" : (aspire === "Dream Home" ? "House" : "Wealth"),
      "aspire": aspire === "Add Own goal" ? data.aspire  : aspire,
      "profile_id": sessionStorage.getItem('profile_id'),
      "plan_for": {
        "profile_details_id": Encryption(data.plan_for)
      },
      "goal_params": {
        "age": profileAge,
        "retire_age": input,
        "life_expectancy": parseInt(assumptions.life_expentancy),
        "post_inflation": 7,
        "post_return": 7,
        "goal_inflation": 7,
        "required_after": input,
        "required_till": input
      },
      "risk_profile": {
        "available": false,
        "pre_return": 8,
        "risk_profile_id": sessionStorage.getItem('profile_id'),
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [
        {
          "amount": data.amount * data.moneyDigits,
          "frequency": 1,
          "no_of_times": 1,
          "gap": 0
        }
      ],
      "actual": [
        {
          "actual_y": 0,
          "frequency": 1,
          "actual_ls": 1,
          "grow": "0"
        }
      ]
    };

    axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        setAddGoal('');

        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })

      });

  }

  const [input, setInput] = useState(1);

  const onChange = (e) => {
    setInput(e.currentTarget.value);
  };

  const [goalFor, setGoalFor] = useState([]);
  const [selfDetails, setSelfDetails] = useState([]);

  // eslint-disable-next-line
  const [assumptions, setAssumptions] = useState([]);


  useEffect(() => {
    console.log("First call on mount..");

    axioInstance.post(`profile-details/getfamily`, {profile_id:sessionStorage.getItem('profile_id')}).then(function (response, data) {


      setGoalFor(response.data)


      let self = response.data.find(product => product.relation === "self");
      setSelfDetails(self);


      const postData = {
        'profile_id': sessionStorage.getItem('profile_id')
      }
      axioInstance.post(`get_assumption_by_profile_id`, postData).then(function (response) {
        setAssumptions(response.data);
      })

    });

    return () => console.log("Cleanup..");
  }, []);


    const [ageFlag, setAgeFlag] = useState(false);
    const [selfAge, setSelfAge] = useState();
    useEffect(() => {
  
      if (ageFlag === false) {
        setProfileAge(ageCalculate(selfDetails.dob));
        setSelfAge(ageCalculate(selfDetails.dob));
        setMax((parseInt(assumptions.life_expentancy) - ageCalculate(selfDetails.dob)) - 1);

      }
  
      console.log('assumptions.life_expentancy', assumptions.life_expentancy);
    }, [max, selfDetails, assumptions, ageFlag]);

  const [profileAge, setProfileAge] = useState();

  const profileSelect = (e) => {

    setProfileAge(ageCalculate(e.target[e.target.selectedIndex].getAttribute('g_dob')));

    setAgeFlag(true);
    setMax((parseInt((assumptions.life_expentancy - selfAge)) - 1));

  };

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => Back()}>Back</button>
            </div>
            <div className="font14 fw500 color182">
              Add {aspire} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
            <div className="font18 fw600 color182 mt50">{aspire}</div>
            <div className="font14 fw500 color485">Add {aspire} plan in your financial journey</div>
          </div>

          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>
              <div className="form-row">
                <div className="col-md-12 pr20">

                  <div className="font14 color212 fw600">Goal Focused On</div>
                  <div className="selectgoal propertysearch mt10">
                    <div className="input-group selectarrow">
                      <select className="custom-select font14 color212 fw500" id="inputGroupSelect01" name='plan_for' {...register("plan_for", { required: 'Required' })} onChange={profileSelect}>
                        <option value="">select</option>

                        {
                          goalFor.map((prof_det, i) => {

                            return (
                              <>
                                <option value={prof_det.profile_details_id} g_dob={prof_det.dob}>{prof_det.full_name}</option>
                              </>
                            )
                          })
                        }
                      </select>
                    </div>
                  </div>

                  <span className="text-danger"> {errors.plan_for && errors.plan_for.message}</span>
                </div>
              </div>


              {
                aspire === "Add Own goal" ? (<><div className="formdevider mt25 mb25" />

                <div className="form-row mt20">
                  <div className="col-md-12 pr20">
                    <label htmlFor="inputEmail4" className="font14 fw500">Aspire</label>
                    <div className="input-inputround">
                      <input type="text" className="form-control font14 color212 fw500" name="aspire" {...register("aspire", { required: 'Enter Goal Name' })} />
                    </div>
                    <span className="text-danger"> {errors.aspire && errors.aspire.message}</span>
                  </div>
                </div></>) : ""
              }
              

              <div className="formdevider mt25 mb25" />

              <div className="form-row mt20">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Required Amount as of Today</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Cost of house today required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="devider_line mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Achieving year</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{input}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">


                    <RangeSlider
                      value={input}
                      onChange={onChange}
                      step={1}
                      min={1}
                      max={max}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={1}
                    />


                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                <div className="font12 fw500 color9ba">{1}</div>
                  <div className="font12 fw500 color9ba">{max}</div>
                </div>

              </div>



              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>
              </div>
            </form>



          </div>
        </div>
      </div>
    </Fragment>
  )
}