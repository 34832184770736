import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import axioInstance from '../../axiosInstance';
//import formatAmount from 'indian-currency-formatter';
import RangeSlider from 'react-bootstrap-range-slider';
import SweetAlert from 'sweetalert2'
import { Encryption } from '../../global';

export default function EditMarriage({ setGoalType, goalId, planLatestStatus }) {

  const { register, handleSubmit, reset, formState: { errors, isDirty, isValid } } = useForm();

  const [state, setState] = useState(false);
  const [goalData, setGoalData] = useState([]);

  const Back = () => {
    setGoalType('');
  }
  const [validate, setValidate] = useState(null);
  const [max, setMax] = useState(35);

  const [age, setAge] = useState(0);
  useEffect(() => {

    setState(true);
    const profile_id = sessionStorage.getItem('profile_id');
    if (profile_id !== null && state !== false) {
      axioInstance.post(`goals/goalbyid`, { "profile_id": profile_id, "goal_id": goalId }).then(
        (response, data) => {


          setGoalData(response.data);

          setInput(parseInt(response.data.required_after)+parseInt(response.data.profile_age))

          setAge(parseInt(response.data.current_age));

          const moneyDigits = response.data.total_abcd.toString().length >= 8 ? 10000000 : 100000;

          setValidate({ goal_id: response.data.goal_id, plan_for: response.data.plan_for, profile_id: response.data.profile_id, profile_age: response.data.profile_age, amount: response.data.total_abcd / moneyDigits, moneyDigits: moneyDigits, year: response.data.required_after+response.data.current_age, goal_name:response.data.goal_name, aspire: response.data.aspire });

          setMax(response.data.profile_age >= 18 ? parseInt(response.data.profile_age)+17 : parseInt(response.data.profile_age)+30);
        });
    }
  }, [state, goalId]);

  // effect runs when user state is updated
  useEffect(() => {
    // reset form with user data
    reset(validate);
    // eslint-disable-next-line
  }, [validate]);




  const onSubmit = data => {

    const life_expectancy = 85;

    const PostData = {
      "goal_type": "Marriage",
      "aspire": data.aspire,
      "goal_name":data.goal_name,
      "goal_id": data.goal_id,
      "profile_id": Encryption(data.profile_id),
      "admin_id":sessionStorage.getItem("admin_id"),
      "plan_for": {
        "profile_details_id": Encryption(data.plan_for)
      },
      "goal_params": {
        "age": data.profile_age,
        "retire_age": data.year,
        "life_expectancy": life_expectancy,
        "post_inflation": 7,
        "post_return": 7,
        "goal_inflation": 7,
        "required_after": input,
        "required_till": input
      },
      "risk_profile": {
        "available": false,
        "pre_return": 8,
        "risk_profile_id": Encryption(data.profile_id),
      },
      "assumptions": {
        "assumption_id": false,
        "inflation": 7.5,
        "income_grow": "10"
      },
      "total_abcd": [
        {
          "amount": data.amount * data.moneyDigits,
          "frequency": 1,
          "no_of_times": 1,
          "gap": 0
        }
      ],
      "actual": [
        {
          "actual_y": 0,
          "frequency": 1,
          "actual_ls": 1,
          "grow": "0"
        }
      ]
    };

    axioInstance.post(`goals/add`, PostData)
      .then(function (response) {
        setGoalType('');

        const profile_id = sessionStorage.getItem('profile_id');
        const gpostData = { profile_id: profile_id };
        axioInstance.post(`goals/reset`, gpostData)
          .then(function (response) {
            axioInstance.post(`profile/summary-update`, { profile_id: profile_id }).then(
              (response, data) => {
                window.location.reload();
              }
            );
          })

      });

  }
  console.log(goalData);

  const [input, setInput] = useState();

  const onChange = (e) => {
    setInput(e.currentTarget.value);
  };

  const DeleteGoal = (id) => {


    SweetAlert.fire({
      title: "Are you sure?",
      text: "you want to delete this goal?",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#09a635",
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
      closeOnConfirm: false,
      closeOnCancel: true
    })
      .then((result) => {
        if (result.isConfirmed) {


          axioInstance.post(`goals/delete`, { profile_id: sessionStorage.getItem('profile_id'), 'goal_id': id }).then(
            function (response) {

              axioInstance.post(`profile/summary-update`, { profile_id: sessionStorage.getItem("profile_id") }).then(
                (response, data) => {
                  SweetAlert.fire(
                    'Goals',
                    'Successfully Deleted.',
                    'success'
                  ).then(function () {
                    window.location.href = "/dashboard";
                  });
                }
              );

            }
          )
        }
      });
  }

  return (
    <Fragment>
      <div className="rightSide_menu_outer">
        <div className="rightSide_menu">

          <div className="rightSide_menu_header">
            <div>
              <button type="button" className="btn btn-outline-primary btn-sm d-none d-md-block d-lg-block" onClick={() => DeleteGoal(goalData.goal_id)}>Delete</button>
            </div>
            <div className="font14 fw500 color182">
              Edit {goalData.goal_name} Goal
            </div>
            <div>&nbsp;</div>
          </div>
          <div className="topBanner topBanner_otherGoals pl25">
            <img src='/images/rupeee.png' alt='img' className='sidebarRupeeeIcon' />
            <div className="font18 fw600 color182">{goalData.goal_name}</div>
            <div className="font14 fw500 color485">{goalData.goal_name} plan in your financial journey</div>
          </div>


          <div className="form_prnt p20">
            <form className="custome_form" onSubmit={handleSubmit(onSubmit)}>

              <div className="expandtab font12 color353 fw500">{goalData.full_name + ' ' + goalData.goal_name}</div>
              <br />
              <small className='text-danger'>{planLatestStatus === 7 && 'Plan Freezed'}</small>
              <input type={"hidden"} name="plan_for" {...register("plan_for")} />
              <input type={"hidden"} name="profile_id" {...register("profile_id")} />
              <input type={"hidden"} name="profile_age" {...register("profile_age")} />
              <input type={"hidden"} name="goal_id" {...register("goal_id")} />
              <input type={"hidden"} name="aspire" {...register("aspire")} />
              <input type={"hidden"} name="goal_name" {...register("goal_name")} />

              <div className="form-row">
                <div className="col-md-12 pr20">
                  <label htmlFor="inputEmail4" className="font14 fw500">Required Amount as of Today</label>
                  <div className="input-group selectarrow">
                    <input type="text" className="form-control font14 color212 fw500" name="amount" {...register("amount", { required: 'Cost of house today required' })} />
                    <select className="custom-select font14" id="inputGroupSelect01" name='moneyDigits' {...register("moneyDigits")}>
                      <option selected value="100000">Lakhs</option>
                      <option value="10000000">Crore</option>
                    </select>
                    <span className="rupicon rupeesign font14 fw500">&#8377;</span>
                  </div>
                  <span className="text-danger"> {errors.amount && errors.amount.message}</span>
                </div>
              </div>

              <div className="formdevider mt25 mb25" />

              <div className="rangesliderbox">
                <div className="rangesliderbox_title">
                  <div>
                    <div className="font14 fw500 color182">Age At marriage</div>
                    <div className="font12 fw500 color8c9">Adjust the estimate year to achive this goal</div>
                  </div>
                  <div className="tragetvalue font13 fw700">{input}</div>
                </div>
                <div className="rangebox_bar mt12">
                  <div className="form-group">
                    <RangeSlider
                      value={input}
                      onChange={onChange}
                      step={1}
                      min={parseInt(age)+1}
                      max={parseInt(max)}
                      className={"form-control-range"}
                      tooltip={"off"}
                      defaultValue={input}
                    />
                  </div>
                </div>
                <div className="flex_center justify_center mt8">
                  <div className="font12 fw500 color9ba">{age+1}</div>
                  <div className="font12 fw500 color9ba">{max}</div>
                </div>
               
              </div>

              <div className="saveBtn backsaveBtn">
                <button type="button" className="btn btn-primary" onClick={() => Back()}>Cancel </button>
                {planLatestStatus !== 7 && <button type="submit" className="btn btn-primary" disabled={!isDirty && !isValid}>Save </button>}
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}